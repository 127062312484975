import React from 'react'
import styled from 'styled-components'

const Status = () => {
  return (
    <Wrapper>
      <a id="finished" />
      <h2 id="construction">Construction</h2>
      <p>La construction de la maison avec MCA</p>
      <p>
        La construction a commencé en{' '}
        <span className="strikethrough">Novembre 2021</span>{' '}
        <i className="strikethrough">après Janvier 2022</i> Mars 2022.
      </p>

      <h2 id="planning">Planning</h2>
      <p className="done">Signer l'acte authentique (Notaire)</p>
      <p className="done">Signer le crédit immobilier</p>
      <p className="done">Attent acceptation permis de construire</p>
      <p className="done">Deposer le permis de construire</p>
      <p className="done">Signer le compromis du terrain (Notaire)</p>
      <p>
        En ligne fini dans 30 minutes. Les deux notaires présents par
        vidéoconférence.
      </p>
      <p className="done">Signer le contrat de construction (MCA)</p>
      <p>
        Reçu par courrier, signé avec vidéoconférence. Simple, efficace. Tout va
        bien jusqu'ici, merci MCA!
      </p>
      <p className="done">Planifier la maison (mars 2021)</p>
      <p>
        Après quelques itérations, le plan d'étage est fait. La maison est
        devenue de plus en plus grande à chaque version. Maintenant il y a 4
        pièces, environ 130m2 et beaucoup de grandes fenêtres. Un toit en
        surplomb de tous les côtés, une isolation à l'eau des fondations, une
        bâche contre l'humidité et le sel pénétrant de l'air marin. Avec
        terrasse, sans garage.
      </p>
      <p className="done">Trouver le terrain (février 2021)</p>
      <p>
        Le terrain est trouvée. A quelques minutes de la plage, tranquillement
        situé à l'orée des bois. Bonus : pas de marécage.
      </p>
    </Wrapper>
  )
}

export default Status

const Wrapper = styled.article`
  text-align: center;
  margin: 0 1rem 2rem 1rem;

  .todo {
    color: #ccc;
    font-size: 1.2em;
  }

  .now {
    color: 333;
    font-weight: bold;
    font-size: 1.5em;
  }

  .done {
    font-style: italic;
    font-size: 1.2em;
  }
`
