import React from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ProjectStatus from '../components/Status'
import Banner from '../components/Banner'
import Seo from '../components/SEO/PageSeo'

const Status = () => {
  return (
    <Layout>
      <Seo />
      <Hero />
      <div className="text-center page-center">
        <h1>La construction de la maison en France: Statut</h1>
        <p>
          À quel stade en est la construction de la maison ? Quelle est l'étape
          suivante dans le projet suivant, lesquels ont déjà été réalisés ?
        </p>
        <div className="underline"></div>

        <div className="posts-center">
          <article>
            <ProjectStatus />
          </article>
          <article>
            <Banner />
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default Status
